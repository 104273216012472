<template>
    <div>
      <v-card >
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">利用規約</span>
          <v-spacer></v-spacer>
        <v-btn 
          icon
          @click="close"
        > 
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
        </v-toolbar>
        <div  class="pa-2"> 
        <v-card-text class="blue-grey lighten-5  overflow-y-auto"   style="max-height: 700px" >
          <DocTerms/>
        </v-card-text>
        </div>
        <v-card-actions>
        
          
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    
      </div>
      

</template> 

<script>
import { mdiClose } from '@mdi/js';

const DocTerms = () => import('@/components/DocTerms.vue');

export default {
  components: { DocTerms },
  name: 'DialogTerms',
  data () {
    return {
     icons:{"close":mdiClose},
    }
  },

  methods: {

    close (){
      this.$emit('closeTermsEmit')
    }
 
  },
}

</script>
